import { css } from '@emotion/react'
import { globalHistory } from '@reach/router'
import { rgba } from 'polished'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { ScrollToggle } from '@/features/common/'
import { SeoMetaTags } from '@/features/common/'
import { useEscKeyFunction } from '@/hooks/useEscKeyFunction'
import { useFocusTrap } from '@/hooks/useFocusTrap'
import { baseGrid, bezier, mq, widthInCols } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { LightboxContent, LightboxContentData } from './LightboxContent'

type Props = {
  data?: LightboxContentData | null
  isOpen: boolean
  onClose: () => void
  entry: {
    title: string
    path: string
  } | null
  layout?: 'FULL' | 'CENTERED'
  slug: string
}

export const Lightbox = ({
  data,
  isOpen,
  onClose = () => null,
  entry,
  slug,
  layout = 'FULL',
}: Props): JSX.Element => {
  const portalTarget =
    typeof window !== `undefined` &&
    document.getElementById('lightbox-container')

  const [closing, setClosing] = useState(false)

  const title = (
    (data?.seoMetaTags as unknown as SeoMetaTags)?.tags.find(
      tag => tag.tagName === 'title' || {}
    ) as {
      content: string
    }
  ).content

  useEffect(() => {
    if (isOpen && !closing) {
      title && (document.title = title)
      setTimeout(() => {
        window.history.pushState({ title: title }, '', slug)
      }, 10)
    }
  }, [isOpen, closing, slug, title])

  const [lightboxRef, setLightboxRef] = useState<HTMLDivElement | null>(
    null
  )
  useFocusTrap({ elements: [lightboxRef], condition: isOpen })

  const transitionDuration = 300

  const handleCloseTransition = useCallback(() => {
    setClosing(true)
    setTimeout(() => {
      setClosing(false)
      onClose()
    }, transitionDuration)
  }, [onClose])

  const handleClose = useCallback(() => {
    if (!closing) {
      window.history.back()
    }
  }, [closing])

  useEffect(() => {
    if (isOpen) {
      globalHistory.listen(({ action }) => {
        // if new page is opened via Link
        if (action === 'PUSH') {
          handleCloseTransition()
        }
        // if lightbox is closed or history.back()
        if (action === 'POP') {
          handleCloseTransition()
          setTimeout(() => {
            if (entry) {
              document.title = entry.title
            }
          }, 10)
        }
      })
    }
  }, [handleCloseTransition, entry, isOpen])

  // used to trigger animations
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setLoaded(true)
      }, 10)
    } else {
      setLoaded(false)
    }
  }, [isOpen])

  useEscKeyFunction(isOpen ? handleClose : () => null)

  const styles = {
    background: css`
      position: fixed;
      width: 100vw;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      transition: background-color ${transitionDuration}ms ease;
      ${loaded &&
      css`
        background-color: ${rgba('#000', 0.9)};
        backdrop-filter: blur(0.333rem) saturate(0);
      `}
      ${(closing || !loaded) &&
      css`
        background-color: transparent;
        backdrop-filter: blur(0);
      `}
    `,
    lightbox: css`
      ${baseGrid}
      box-sizing: border-box;
      position: fixed;
      top: 0;
      left: 0;
      overflow-y: scroll;
      width: 100vw;
      height: 100%;
      z-index: 9;
      transition:
        opacity ${transitionDuration}ms ease,
        transform ${transitionDuration}ms ease,
        height 500ms ease,
        top 500ms ease;
      ${loaded &&
      css`
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `}
      ${(closing || !loaded) &&
      css`
        opacity: 0;
        transform: translate3d(-6rem, 0, 0);
      `}
    `,
    content: css`
      grid-row: 1 / 2;
      justify-self: stretch;
      align-self: center;
      display: grid;
      --border-width: 1.5rem;
      grid-template-columns: var(--border-width) 1fr var(--border-width);
      grid-template-rows: var(--border-width) 1fr var(--border-width);
      position: relative;
      background: ${colors.red};
      ${layout === 'FULL' &&
      css`
        grid-column: 1 / span 12;
        --grid-w: calc(
          100vw - ${widthInCols({ count: 1, gridWidth: '100vw' })} - var(
              --margin
            ) - var(--gtr) - 2 * var(--border-width)
        );
        ${mq().ml} {
          grid-column: 1 / span 13;
          --grid-w: calc(
            var(--border-width) +
              ${widthInCols({ count: 12, gridWidth: '100vw' })}
          );
        }
        ${mq().s} {
          --border-width: 0.75rem;
          grid-column: 1 / -1;
          margin-right: var(--gtr);
          --grid-w: calc(100vw - var(--gtr) - 2 * var(--border-width));
        }
      `}
      ${layout === 'CENTERED' &&
      css`
        grid-column: 3 / -3;
        --grid-w: min(
          calc(${widthInCols({ count: 10, gridWidth: '100vw' })}),
          90ch
        );
        justify-self: center;
        margin: var(--row-36) 0;
        max-width: 90ch;
        width: 100%;
        ${mq().ms} {
          grid-column: 2 / -2;
          --grid-w: min(
            calc(${widthInCols({ count: 12, gridWidth: '100vw' })}),
            90ch
          );
        }
      `}
    `,
    closeButton: css`
      position: sticky;
      z-index: 9;
      grid-column: 2 / 4;
      grid-row: 1 / 3;
      top: 0px;
      display: flex;
      align-self: flex-start;
      justify-self: flex-end;
      padding: 1rem;
      color: #fff;
      background: ${colors.red};
      transition:
        color 200ms ease,
        transform 300ms ease-out,
        opacity 450ms ease,
        top 500ms ease;
      svg {
        width: 3rem;
        height: 3rem;
        overflow: visible;
        transition: transform 500ms ${bezier.bounce};
        transform: translate3d(-0.2rem, 0, 0);
        path {
          fill: none;
          stroke: currentColor;
          stroke-width: 3;
        }
        ${mq().ms} {
          width: 2rem;
          height: 2rem;
          path {
            stroke-width: 4;
          }
        }
      }
      @media (hover: hover) {
        &:hover {
          svg {
            transform: translate3d(-0.5rem, 0, 0);
            ${mq().ms} {
              transform: translate3d(-0.333rem, 0, 0);
            }
          }
        }
      }
    `,
    backgroundClose: css`
      position: relative;
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      z-index: 0;
      ${closing &&
      css`
        display: none;
      `}
    `,
  }

  if (isOpen && portalTarget) {
    return createPortal(
      <Fragment>
        <ScrollToggle />
        <div css={styles.background} />
        <div
          css={styles.lightbox}
          ref={node => setLightboxRef(node)}
        >
          <div
            css={styles.backgroundClose}
            onClick={handleClose}
            aria-hidden
          />
          <div css={styles.content}>
            <button
              aria-hidden
              tabIndex={-1}
              style={{ width: 0, height: 0 }}
            />
            <LightboxContent data={data} />
            <button
              css={styles.closeButton}
              aria-label="Close Lightbox"
              onClick={handleClose}
              onKeyPress={handleClose}
            >
              <svg
                viewBox="0 0 19 33"
                fill="none"
              >
                <path
                  d="M17 2L3 15.5869L17 31"
                  vectorEffect="non-scaling-stroke"
                />
              </svg>
            </button>
          </div>
        </div>
      </Fragment>,
      portalTarget
    )
  }
  return <Fragment />
}
